import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from '../components/Typography';

// import exterior1 from "../images/360/01_23MY_OU_PHEV_titanium.png";
// import exterior2 from "../images/360/02_23MY_OU_PHEV_black_diamond.png";
// import exterior3 from "../images/360/03_23MY_OU_PHEV_black_mica.png";
// import exterior4 from "../images/360/04_23MY_OU_PHEV_white_diamond2.png";
// import exterior5 from "../images/360/05_23MY_OU_PHEV_red_diamond.png";
// import exterior6 from "../images/360/06_23MY_OU_PHEV_sterling_silver.png";
import exterior1 from "../images/360_new/01-mitsubishi-l200-blade-silver-metallic.png";
import exterior2 from "../images/360_new/02-mitsubishi-l200-blade-silver-metallic.png";
import exterior3 from "../images/360_new/03-mitsubishi-l200-white-solid.png";
import exterior4 from "../images/360_new/04-mitsubishi-l200-graphite-grey-metallic.png";
import exterior5 from "../images/360_new/05-mitsubishi-l200-red-solid.png";
import exterior6 from "../images/360_new/06-mitsubishi-l200-impulse-blue-metalic.png";
import exterior7 from "../images/360_new/07-mitsubishi-l200-blade-silver-metallic.png";
import exterior8 from "../images/360_new/08-mitsubishi-l200-graphite-grey-metallic.png";
import exterior9 from "../images/360_new/09-mitsubishi-l200-impulse-blue-metalic.png";
import exterior10 from "../images/360_new/10-mitsubishi-l200-red-solid.png";


import { EffectCoverflow, Navigation } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};


function ProductValues() {
  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };
  return (
    <Box id="diseno"
      component="section"
      sx={{
        display: "flex",
        overflow: "hidden",
        background:
          "linear-gradient(97deg, rgba(33,33,33,1) 0%, rgba(25,25,25,1) 22%, rgba(26,26,26,1) 68%, rgba(39,39,39,1) 86%, rgba(37,36,36,1) 100%)"
      }}
    >
      <Grid
        container
        style={{
          marginTop: isMobile()?  '60px' :"120px",
          marginBottom: isMobile()?  '100px' :"140px",
          display: "flex",
          position: "relative",
          placeContent: "space-around",
        }}
      >
        <Grid
          item
          xs={10} md={12}
          style={{
            fontSize: "clamp(2em, 5vw, 3rem)",
            marginTop: "0",
            color: "white",
            textAlign: "center",
            alignContent: "center",
            marginBottom: "50px",
            fontFamily: "mmcregular",
            letterSpacing: "4",
            lineHeight:"1em"
          }}
        >
          NUEVO Y ROBUSTO DISEÑO EXTERIOR
        </Grid>
        <Grid item xs={10} sm={6} xl={4}>
          <Typography style={{
            marginTop: isMobile()?  '-20px' :"-20px",
            fontFamily: "mmcregular",
            lineHeight:"1.1em",
            fontSize: "clamp(1.2rem, 5vw, 0.4rem)"     
          }} align="center" component="h1" color="white">
            Luce una imagen renovada con elementos únicos que la hacen destacar en cualquier camino. 
          </Typography>

        </Grid>


        <Grid container sx={{ marginTop: isMobile()?  '10px' :"-50px", }}>
          <Swiper
            navigation={true}
            slidesPerView={1}
            loop={true}
            centeredSlides={true}
            effect={"coverflow"}
            spaceBetween={isMobile()? 200 : 1}
            modules={[Navigation, EffectCoverflow]}
            className="mySwiper"
            coverflowEffect={{
              rotate: 0,
              stretch: 10,
              depth: 1500,
              modifier: 1,
              slideShadows: false,
            }}
          >
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={exterior1}
                alt="Blade_Silver"
                sx={{ maxWidth: '75%' }}
              />
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-20px' :"-85px",
                  letterSpacing: "0.05em"
                }}
              >
                BLADE SILVER METALLIC
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={exterior2}
                alt="Graphite_Grey"
                sx={{ maxWidth: '75%' }}
              />
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-20px' :"-85px",
                  letterSpacing: "0.05em"
                }}
              >
                BLADE SILVER METALLIC
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              
              <Box
                component="img"
                src={exterior3}
                alt="White_Solid"
                sx={{ maxWidth: '75%' }}
              />
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                WHITE SOLID
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={exterior4}
                alt="Graphite_Grey"
                sx={{ maxWidth: '66%', marginTop: '10px' }}
              />
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                GRAPHITE GREY METALLIC
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={exterior5}
                alt="Red_Solid"
                sx={{ maxWidth: '68%' }}
              />
              
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                RED SOLID
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={exterior6}
                alt="Impulse_Blue"
                sx={{ maxWidth: '68%' }}
              />
              
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                IMPULSE BLUE METALLIC
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={exterior7}
                alt="Silver Metallic"
                sx={{ maxWidth: '68%' }}
              />
              
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                BLADE SILVER METALLIC 
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={exterior8}
                alt="Graphite Grey"
                sx={{ maxWidth: '68%' }}
              />
              
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                GRAPHITE GREY METALLIC
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={exterior9}
                alt="Impulse Blue"
                sx={{ maxWidth: '68%' }}
              />
              
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                IMPULSE BLUE METALLIC
              </Typography>
            </SwiperSlide>
            <SwiperSlide style={{
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}>

              <Box
                component="img"
                src={exterior10}
                alt="Red Solid"
                sx={{ maxWidth: '68%' }}
              />
              
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 5vw, 2.2rem)",
                  fontStyle: "bold",
                  fontFamily: "mmcoffice",
                  marginBottom: "20px",
                  marginTop: isMobile()?  '-10px' :"-75px",
                  letterSpacing: "0.05em"
                }}
              >
                RED SOLID
              </Typography>
            </SwiperSlide>
          </Swiper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProductValues;
