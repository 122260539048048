import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';


import Typography from '../components/Typography';
import Grid from "@mui/material/Grid";
import stylesf from './section.css'
import { height } from '@mui/system';
import section2 from '../images/section1.png'
import section3 from '../images/section3motors.png'
import modo1 from '../images/manejo/manejo-l200-mitsubishi-01.jpg'
import modo2 from '../images/manejo/manejo-l200-mitsubishi-02.jpg'
import modo3 from '../images/manejo/manejo-l200-mitsubishi-03.jpg'
import modo4 from '../images/manejo/manejo-l200-mitsubishi-04.jpg'
import modo5 from '../images/manejo/manejo-l200-mitsubishi-05.jpg'

import equip1 from '../images/equipamiento/equipamiento-l200-mitsubishi-01.jpg'
import equip2 from '../images/equipamiento/equipamiento-l200-mitsubishi-02.jpg'
import equip3 from '../images/equipamiento/equipamiento-l200-mitsubishi-03.jpg'
import equip4 from '../images/equipamiento/equipamiento-l200-mitsubishi-04.jpg'
import equip5 from '../images/equipamiento/equipamiento-l200-mitsubishi-05.jpg'
import equip6 from '../images/equipamiento/equipamiento-l200-mitsubishi-06.jpg'
import equip7 from '../images/equipamiento/equipamiento-l200-mitsubishi-07.jpg'


import motores from '../images/motores.png'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import InteriorMobile from "./interiorMobile";
import AtributeMobile from "./atributeMobile";


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ProductHowItWorks from '../../modules/views/ProductHowItWorks';
import axios from "axios";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import fondosr from '../images/footer-l200-mitsubishi.jpg'
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { SendToMobileOutlined } from "@mui/icons-material";

import { Swiper, SwiperSlide } from "swiper/react";

import { EffectCoverflow, Navigation } from "swiper";
import Box from "@mui/material/Box";
import Form from "./Form";





const MenuProps = {
  PaperProps: {
    sx: {
      '& .MuiMenuItem-root': {
        fontSize: 10,
      },
    },
    style: {
      fontSize: 10,
    },
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function ProductCategories(props) {
  const { set, typeDialog, setTypeDialog, url } = props
  const [open, setOpen] = useState(false);
  const [validar, setValidar] = React.useState(false);
  const [asentamiento, setAsentamiento] = useState([])
  const [datadealers, setDataDealers] = useState([])
  const [valueGenero, setValueGenero] = React.useState('FEMENINO');
  const [valueTiempo, setValueTiempo] = React.useState('0 A 3 MESES');
  const [privacidad, setPrivacidad] = React.useState(false);
  const [terminos, setTerminos] = React.useState(false);
  const [mobile, setMobile] = useState(false)
  const [openAler, setOpenAlert] = React.useState(false);
  
  const [disabledButton, setDisabledButton] = React.useState(false);



  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };


  useEffect(() => {

    if (isMobile()) {
      setMobile(true)
    }

  }, []);




  const hasStock = async () => {
    
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/status_stock`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
      });

      if (!response) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response.json();
      
      console.log("response", result.status);
      //setDisabledButton(result.status);
      return result.status;
    } catch (err) {

      console.log(err);
	  //setDisabledButton(false);
	  return false;
    }
  }


					   


  const handleClosAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };



  hasStock();

  return (
    <React.Fragment>
      <div class="wrapper">
        {/* INICIO SECCION DE MOTORES */}
		<a id="manejo"> </a>
        <section id="tecnologia1">
			
          <div class="section-content has-diagonal" style={{ marginTop: isMobile()?  '10px' :"40px", marginBottom: "160px" }}>
            <div style={{ margin: "1%" }}>

              <Grid container columnSpacing={1} style={{ marginTop: "0px", marginBottom: "0px", placeContent: "space-around" }}>


              <Grid item xs={12} md={12} lg={12} sx={{ alignItems: "center"}}>
                <Typography style={{
                    fontSize: "clamp(2em, 5vw, 3rem)",
                    marginTop: +isMobile()?  '60px' :"0px",
                    color: "white",
                    textAlign: "center",
                    alignContent: "center",
                    marginBottom: "50px",
                    fontFamily: "mmcregular",
                    letterSpacing: "4",
                    lineHeight:"1em"
                  }} align="center" component="h1" color="white">
                    MANEJO
                </Typography>
              </Grid>
              <Grid item xs={10} sm={6} xl={4}>
                <Typography style={{
                  marginTop: isMobile()?  '-20px' :"-20px",
                  marginBottom: "60px",
                  fontFamily: "mmcregular",
                  lineHeight:"1.1em",
                  fontSize: "clamp(1.2rem, 5vw, 0.4rem)"     
                }} align="center" component="h1" color="white">
                  La tecnología de MITSUBISHI L200 2025 la convierte en una poderosa herramienta que convertirá cada trayecto en una aventura. 
                </Typography>

              </Grid>


                <Grid item xs={10} sm={12} sx={{ marginBottom: '20px', alignItems: "center" }} >

                  {/* <Typography style={{
                    marginTop: isMobile()?  '-30px' :"-10px",
                    marginBottom: isMobile()?  '10px' :"20px",
                    fontFamily: "mmcregular",
                    fontSize: "clamp(1.2em, 5vw, 1.8rem)",
                    letterSpacing: "0.1em",
                    lineHeight: "1em"

                  }} align="center" component="h1" color="#cf0303" >

                    CONDUCCIÓN ELÉCTRICA HÍBRIDA
                  </Typography> */}

                </Grid>

                <Swiper
                  navigation={true}
                  slidesPerView={isMobile()? 1 : 3}
                  loop={true}
                  centeredSlides={true}
                  effect={"coverflow"}
                  modules={[Navigation]}
                  className="mySwiper"
                >

                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                }}>

                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>

                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>

                        <center>
                          <Box
                            component="img"
                            src={modo2}
                            alt="modo2"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />

                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            CONTROL ACTIVO EN CURVA (AYC) PARA AYUDARTE A DOMINAR EL CAMINO.
                          </Typography>
                          {/* <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.6rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcregular",
                              marginBottom: "20px",
                              marginTop: '10px',
                              letterSpacing: "0em",
                              maxWidth: '80%'

                            }}
                          >
                            7 MODOS DE MANEJO
                          </Typography> */}
                        </center>
                      </Grid>

                      
                    </Grid>
                  </SwiperSlide>

                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                }}>

                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>

                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>

                        <center>
                          <Box
                            component="img"
                            src={modo3}
                            alt="modo3"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />

                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            LLEVA LA AVENTURA A TODOS LADOS CON LAS ASISTENCIAS PARA ASCENSO Y DESCENSO DE PENDIENTES.
                          </Typography>
                          {/* <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.6rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcregular",
                              marginBottom: "20px",
                              marginTop: '10px',
                              letterSpacing: "0em",
                              maxWidth: '80%'

                            }}
                          >
                            7 MODOS DE MANEJO
                          </Typography> */}
                        </center>
                      </Grid>

                      
                    </Grid>
                  </SwiperSlide>

                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                }}>

                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>

                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>

                        <center>
                          <Box
                            component="img"
                            src={modo4}
                            alt="modo4"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />

                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            7 MODOS DE MANEJO PARA UN SINFÍN DE POSIBILIDADES.
                          </Typography>
                          {/* <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.6rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcregular",
                              marginBottom: "20px",
                              marginTop: '10px',
                              letterSpacing: "0em",
                              maxWidth: '80%'

                            }}
                          >
                            7 MODOS DE MANEJO
                          </Typography> */}
                        </center>
                      </Grid>

                      
                    </Grid>
                  </SwiperSlide>

                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                }}>

                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>

                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>

                        <center>
                          <Box
                            component="img"
                            src={modo5}
                            alt="modo5"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />

                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            7 MODOS DE MANEJO PARA UN SINFÍN DE POSIBILIDADES.
                          </Typography>
                          {/* <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.6rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcregular",
                              marginBottom: "20px",
                              marginTop: '10px',
                              letterSpacing: "0em",
                              maxWidth: '80%'

                            }}
                          >
                            7 MODOS DE MANEJO
                          </Typography> */}
                        </center>
                      </Grid>

                      
                    </Grid>
                  </SwiperSlide>

                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                }}>

                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>

                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>

                        <center>
                          <Box
                            component="img"
                            src={modo1}
                            alt="modo1"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />

                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            CON SUS CAPACIDADES 4X4 CON SUPER SELECT 4WD, L200 2025 NO CONOCE OBSTÁCULOS.
                          </Typography>
                          {/* <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.6rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcregular",
                              marginBottom: "20px",
                              marginTop: '10px',
                              letterSpacing: "0em",
                              maxWidth: '80%'

                            }}
                          >
                            7 MODOS DE MANEJO
                          </Typography> */}
                        </center>
                      </Grid>

                      
                    </Grid>
                  </SwiperSlide>

                  

                  {/* <SwiperSlide>

                    <Grid container >
                      {isMobile()? (
                      <><Grid item xs={11} sm={6} >

                          <center>
                            <Box
                              component="img"
                              src={modo4}
                              alt="modo4"
                              sx={{ maxWidth: isMobile()? '100%' :"80%", marginTop: isMobile()? '20px' :"40px", border: "1px solid #4D4D4D" }} />

                          </center>
                        </Grid><Grid item xs={12} sm={6} sx={{marginTop:"-40px"}}>
                        <center>
                            <Typography align="center"
                              style={{
                                color: "white",
                                fontSize: "clamp(0.8rem, 3vw, 1.2rem)",
                                fontStyle: "normal",
                                fontFamily: "mmcregular",
                                marginBottom: "20px",
                                marginTop: '60px',
                                letterSpacing: "0.15em",
                                maxWidth: '80%',
                              }}
                            >
                              SISTEMA TWIN-MOTOR S-AWC (SUPER-ALL WHEEL CONTROL), EXCLUSIVO DE MITSUBISHI MOTORS
                            </Typography>
                            <Typography align="center"
                              style={{
                                color: "white",
                                fontSize: "clamp(0.4rem, 3vw, 1.2rem)",
                                fontStyle: "normal",
                                fontFamily: "mmcregular",
                                marginBottom: "20px",
                                marginTop: '10px',
                                letterSpacing: "0.15em",
                                maxWidth: '88%',
                              }}
                            >

                              Controla eléctricamente con ayuda de los motores gemelos para distribuir la fuerza motriz más libremente entre las ruedas delanteras y traseras, y así, mejorar el rendimiento en el camino ante diversas condiciones.
                            </Typography>
                            </center>
                          </Grid></>
):<><Grid item xs={6} sm={6}>

<center>
  <Box
    component="img"
    src={modo4}
    alt="modo1"
    sx={{ maxWidth: '80%' }} />

</center>
</Grid><Grid item xs={12} sm={6}>
  <Typography align="left"
    style={{
      color: "white",
      fontSize: "20px",
      fontStyle: "normal",
      fontFamily: "mmcmedium",
      marginBottom: "20px",
      marginTop: '60px',
      letterSpacing: "0.15em",
      maxWidth: '88%',
    }}
  >

  ASISTENCIAS PARA ASCENSO Y DESCENSO DE PENDIENTES
  </Typography>
  <Typography align="left"
    style={{
      color: "white",
      fontSize: "16px",
      fontStyle: "normal",
      fontFamily: "mmcregular",
      marginBottom: "20px",
      marginTop: '10px',
      letterSpacing: "0.15em",
      maxWidth: '88%',
    }}
  >

<strong>Asistencia de arranque en pendientes</strong>
<br></br>El sistema HSA  te ayuda a no rodar hacia atrás , manteniendo la fuerza de frenado hasta por 2 segundos después de soltar el freno, garantizando así un arranque seguro hasta que la aceleración sea aplicada.
<br></br>
<br></br>
<strong>Control de descenso en pendientes</strong>
<br></br>El HDC frena y mantiene la velocidad mientras viajas cuesta abajo para una dirección más segura sin necesitar utilizar el freno de mano.
  </Typography>
</Grid></>}
                    </Grid>
                  </SwiperSlide> */}

                </Swiper>


              </Grid>

            </div>
          </div>
          <div class="section-mask bg-option-2 has-parallax" style={{
            marginTop: '0px',
            background:
              "#000000"
          }}>
            <hr style={{ marginTop: "-2px", height: "3px" }} color="#cf0303" />
          </div>
        </section >
        {/* FIN SECCION MOTORES */}
    {/* INICIO SECCION DE EQUIPAMIENTO */}
    <a id="trabajo"> </a>
        <section id="trabajo1">
			
          <div class="section-content has-diagonal">

            <AtributeMobile />

          </div>
          <div class="section-mask" style={{
            background:
              " linear-gradient(48deg, rgba(37,37,37,1) 0%, rgba(21,21,21,0.989233193277311) 8%, rgba(21,21,21,1) 52%, rgba(21,21,21,1) 66%, rgba(61,60,63,0.9752275910364145) 97%)"
          }}>           <hr style={{ marginTop: "-2px", height: "3px" }} color="#cf0303" />
          </div>
        </section>
        {/* FIN SECCION DE INTERIORES */}
    {/* INICIO SECCION DE EQUIPAMIENTO */}
		<a id="equipamiento"> </a>
        <section id="equipamiento">
			
          <div class="section-content has-diagonal" style={{ marginTop: isMobile()?  '10px' :"40px", marginBottom: "140px" }}>
            <div style={{ margin: "1%" }}>

              <Grid container columnSpacing={1} style={{ marginTop: "0px", marginBottom: "-20px", placeContent: "space-around" }}>


              <Grid item xs={10} md={12} lg={12} sx={{ alignItems: "center"}}>
                <Typography style={{
                    fontSize: "clamp(2em, 5vw, 3rem)",
                    marginTop: +isMobile()?  '60px' :"0px",
                    color: "white",
                    textAlign: "center",
                    alignContent: "center",
                    marginBottom: "50px",
                    fontFamily: "mmcregular",
                    letterSpacing: "4",
                    lineHeight:"1em"
                  }} align="center" component="h1" color="white">
                    EQUIPAMIENTO
                </Typography>
              </Grid>
              <Grid item xs={10} sm={6} xl={4}>
                <Typography style={{
                  marginTop: isMobile()?  '-20px' :"-20px",
                  marginBottom: "60px",
                  fontFamily: "mmcregular",
                  lineHeight:"1.1em",
                  fontSize: "clamp(1.2rem, 5vw, 0.4rem)"     
                }} align="center" component="h1" color="white">
                  L200 2025 viene equipada con detalles hechos para maximizar tu trabajo, herramientas a la altura de cada aventura.
                </Typography>

              </Grid>


                <Grid item xs={10} sm={12} sx={{ marginBottom: '20px', alignItems: "center" }} >

                  {/* <Typography style={{
                    marginTop: isMobile()?  '-30px' :"-10px",
                    marginBottom: isMobile()?  '10px' :"20px",
                    fontFamily: "mmcregular",
                    fontSize: "clamp(1.2em, 5vw, 1.8rem)",
                    letterSpacing: "0.1em",
                    lineHeight: "1em"

                  }} align="center" component="h1" color="#cf0303" >

                    CONDUCCIÓN ELÉCTRICA HÍBRIDA
                  </Typography> */}

                </Grid>

                <Swiper
                  navigation={true}
                  slidesPerView={isMobile()? 1 : 3}
                  loop={true}
                  centeredSlides={true}
                  effect={"coverflow"}
                  modules={[Navigation]}
                  className="mySwiper"
                >

                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                }}>

                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>

                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>

                        <center>
                          <Box
                            component="img"
                            src={equip2}
                            alt="equip2"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />

                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            BEDLINER CON PROTECCIÓN ADICIONAL PARA USO RUDO.
                          </Typography>
                          {/* <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.6rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcregular",
                              marginBottom: "20px",
                              marginTop: '10px',
                              letterSpacing: "0em",
                              maxWidth: '80%'

                            }}
                          >
                            7 MODOS DE MANEJO
                          </Typography> */}
                        </center>
                      </Grid>

                      
                    </Grid>
                  </SwiperSlide>

                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                }}>

                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>

                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>

                        <center>
                          <Box
                            component="img"
                            src={equip3}
                            alt="equip3"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />

                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            CANTONERAS OFF-ROAD QUE TE HARÁN SOBRESALIR EN CUALQUIER CAMINO.
                          </Typography>
                          {/* <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.6rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcregular",
                              marginBottom: "20px",
                              marginTop: '10px',
                              letterSpacing: "0em",
                              maxWidth: '80%'

                            }}
                          >
                            7 MODOS DE MANEJO
                          </Typography> */}
                        </center>
                      </Grid>

                      
                    </Grid>
                  </SwiperSlide>

                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                }}>

                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>

                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>

                        <center>
                          <Box
                            component="img"
                            src={equip4}
                            alt="equip4"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />

                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            ROLL BAR NEGRA QUE LE AGREGA FUERZA Y SEGURIDAD A TUS VIAJES.
                          </Typography>
                          {/* <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.6rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcregular",
                              marginBottom: "20px",
                              marginTop: '10px',
                              letterSpacing: "0em",
                              maxWidth: '80%'

                            }}
                          >
                            7 MODOS DE MANEJO
                          </Typography> */}
                        </center>
                      </Grid>

                      
                    </Grid>
                  </SwiperSlide>

                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                }}>

                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>

                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>

                        <center>
                          <Box
                            component="img"
                            src={equip5}
                            alt="equip5"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />

                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            TAPETES DE USO RUDO POR SI TU AVENTURA LO NECESITA.
                          </Typography>
                          {/* <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.6rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcregular",
                              marginBottom: "20px",
                              marginTop: '10px',
                              letterSpacing: "0em",
                              maxWidth: '80%'

                            }}
                          >
                            7 MODOS DE MANEJO
                          </Typography> */}
                        </center>
                      </Grid>

                      
                    </Grid>
                  </SwiperSlide>

                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                }}>

                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>

                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>

                        <center>
                          <Box
                            component="img"
                            src={equip6}
                            alt="equip6"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />

                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            EMBLEMA DE COFRE CROMADO PARA MOSTRAR EL SELLO CARACTERÍSTICO DE MITSUBISHI MOTORS.
                          </Typography>
                          {/* <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.6rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcregular",
                              marginBottom: "20px",
                              marginTop: '10px',
                              letterSpacing: "0em",
                              maxWidth: '80%'

                            }}
                          >
                            7 MODOS DE MANEJO
                          </Typography> */}
                        </center>
                      </Grid>

                      
                    </Grid>
                  </SwiperSlide>

                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                }}>

                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>

                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>

                        <center>
                          <Box
                            component="img"
                            src={equip7}
                            alt="equip7"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />

                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            ESTRIBOS LATERALES PARA ACCEDER MÁS FÁCIL Y QUE OFRECEN SEGURIDAD ADICIONAL ANTE IMPACTOS.
                          </Typography>
                          {/* <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.6rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcregular",
                              marginBottom: "20px",
                              marginTop: '10px',
                              letterSpacing: "0em",
                              maxWidth: '80%'

                            }}
                          >
                            7 MODOS DE MANEJO
                          </Typography> */}
                        </center>
                      </Grid>

                      
                    </Grid>
                  </SwiperSlide>

                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                }}>

                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>

                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>

                        <center>
                          <Box
                            component="img"
                            src={equip1}
                            alt="equip1"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />

                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            PANTALLA TOUCHSCREEN DE 8" PARA TENER TODO EL CONTROL A TUS MANOS.
                          </Typography>
                          {/* <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.6rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcregular",
                              marginBottom: "20px",
                              marginTop: '10px',
                              letterSpacing: "0em",
                              maxWidth: '80%'

                            }}
                          >
                            7 MODOS DE MANEJO
                          </Typography> */}
                        </center>
                      </Grid>

                      
                    </Grid>
                  </SwiperSlide>

                  

                  {/* <SwiperSlide>

                    <Grid container >
                      {isMobile()? (
                      <><Grid item xs={11} sm={6} >

                          <center>
                            <Box
                              component="img"
                              src={modo4}
                              alt="modo4"
                              sx={{ maxWidth: isMobile()? '100%' :"80%", marginTop: isMobile()? '20px' :"40px", border: "1px solid #4D4D4D" }} />

                          </center>
                        </Grid><Grid item xs={12} sm={6} sx={{marginTop:"-40px"}}>
                        <center>
                            <Typography align="center"
                              style={{
                                color: "white",
                                fontSize: "clamp(0.8rem, 3vw, 1.2rem)",
                                fontStyle: "normal",
                                fontFamily: "mmcregular",
                                marginBottom: "20px",
                                marginTop: '60px',
                                letterSpacing: "0.15em",
                                maxWidth: '80%',
                              }}
                            >
                              SISTEMA TWIN-MOTOR S-AWC (SUPER-ALL WHEEL CONTROL), EXCLUSIVO DE MITSUBISHI MOTORS
                            </Typography>
                            <Typography align="center"
                              style={{
                                color: "white",
                                fontSize: "clamp(0.4rem, 3vw, 1.2rem)",
                                fontStyle: "normal",
                                fontFamily: "mmcregular",
                                marginBottom: "20px",
                                marginTop: '10px',
                                letterSpacing: "0.15em",
                                maxWidth: '88%',
                              }}
                            >

                              Controla eléctricamente con ayuda de los motores gemelos para distribuir la fuerza motriz más libremente entre las ruedas delanteras y traseras, y así, mejorar el rendimiento en el camino ante diversas condiciones.
                            </Typography>
                            </center>
                          </Grid></>
):<><Grid item xs={6} sm={6}>

<center>
  <Box
    component="img"
    src={modo4}
    alt="modo1"
    sx={{ maxWidth: '80%' }} />

</center>
</Grid><Grid item xs={12} sm={6}>
  <Typography align="left"
    style={{
      color: "white",
      fontSize: "20px",
      fontStyle: "normal",
      fontFamily: "mmcmedium",
      marginBottom: "20px",
      marginTop: '60px',
      letterSpacing: "0.15em",
      maxWidth: '88%',
    }}
  >

  ASISTENCIAS PARA ASCENSO Y DESCENSO DE PENDIENTES
  </Typography>
  <Typography align="left"
    style={{
      color: "white",
      fontSize: "16px",
      fontStyle: "normal",
      fontFamily: "mmcregular",
      marginBottom: "20px",
      marginTop: '10px',
      letterSpacing: "0.15em",
      maxWidth: '88%',
    }}
  >

<strong>Asistencia de arranque en pendientes</strong>
<br></br>El sistema HSA  te ayuda a no rodar hacia atrás , manteniendo la fuerza de frenado hasta por 2 segundos después de soltar el freno, garantizando así un arranque seguro hasta que la aceleración sea aplicada.
<br></br>
<br></br>
<strong>Control de descenso en pendientes</strong>
<br></br>El HDC frena y mantiene la velocidad mientras viajas cuesta abajo para una dirección más segura sin necesitar utilizar el freno de mano.
  </Typography>
</Grid></>}
                    </Grid>
                  </SwiperSlide> */}

                </Swiper>


              </Grid>

            </div>
          </div>
          <div class="section-mask bg-option-2 has-parallax" style={{
            marginTop: '0px',
            background:
              "#000000"
          }}>
            <hr style={{ marginTop: "-2px", height: "3px" }} color="#cf0303" />
          </div>
        </section >
        {/* FIN SECCION EQUIPAMIENTO */}
		{/* <a id="interior"> </a>
        <section id="interior1">
			
          <div class="section-content has-diagonal">

            <InteriorMobile />

          </div>
          <div class="section-mask" style={{
            background:
              " linear-gradient(48deg, rgba(37,37,37,1) 0%, rgba(21,21,21,0.989233193277311) 8%, rgba(21,21,21,1) 52%, rgba(21,21,21,1) 66%, rgba(61,60,63,0.9752275910364145) 97%)"
          }}>           <hr style={{ marginTop: "-2px", height: "3px" }} color="#cf0303" />
          </div>
        </section> */}
        {/* FIN SECCION DE INTERIORES */}
    
        {/* seccion4  */}
        {/* <section>

          <div class="section-mask bg-option-2 has-parallax" style={{
            position: "relative",
            marginTop: '2px',
            background:
              " linear-gradient(74deg, rgba(51,48,51,1) 0%, rgba(23,21,21,1) 19%, rgba(21,21,21,1) 61%, rgba(21,21,21,1) 84%, rgba(39,39,40,1) 100%)"

          }}>

            <hr style={{ marginTop: "-2px", height: "3px" }} color="#cf0303" />

            <img
              src={fondosr}
              alt="increase priority"
              style={{
                marginTop: "-8px", color: "#4D4D4D", maxWidth: '100%', height: "auto"
              }}
            />

          </div>
        </section > */}

        {/* seccion 5 */}
		<a id="apartala">.</a>
        <section id="apartala1" style={{ marginTop: "-80px" }}>
			
          <div class="section-content has-diagonal" style={{ marginTop: '100px', marginBottom: "100px" }}>

            <Grid container spacing={2} style={{ marginTop: +isMobile()?  '60px' :"80px", marginBottom: +isMobile()?  '60px' :"120px", placeContent: "space-around" }}>

              <Grid item xs={10} md={12} lg={10} sx={{ alignItems: "center"}}>
                <Typography style={{
                    fontSize: "clamp(2em, 5vw, 3rem)",
                    marginTop: 0,
                    color: "white",
                    textAlign: "center",
                    alignContent: "center",
                    marginBottom: "0",
                    fontFamily: "mmcregular",
                    letterSpacing: "4",
                    lineHeight:"1em"
                  }} align="center" component="h1" color="white">
                    LA FUERZA, TECNOLOGÍA E INNOVACIÓN DE L200 FUERON DISEÑADAS PARA TI, SÉ DE LOS PRIMEROS EN CONSEGUIR LA TUYA. 
                </Typography>
              </Grid>
            </Grid>

            <Form set={set} setTypeDialog={setTypeDialog} typeDialog={typeDialog} currentUrl={url}/>

          </div>
          <div class="section-mask bg-option-2 has-parallax" style={{
            marginTop: '100px',
            background:
              " linear-gradient(74deg, rgba(51,48,51,1) 0%, rgba(23,21,21,1) 19%, rgba(21,21,21,1) 61%, rgba(21,21,21,1) 84%, rgba(39,39,40,1) 100%)"
          }}>
            <hr style={{ position: "relative", marginTop: "-2px", height: "3px" }} color="#cf0303" />

          </div>

        </section >

        <section>

          <div style={{
            position: "relative",
            marginTop: '2px',
            background: "black"
          }}>


            <img
              src={fondosr}
              alt="increase priority"
              style={{
                marginTop: "-8px", color: "#4D4D4D", maxWidth: '100%', height: "auto"
              }}
            />

          </div>


        </section>
      </div >
      

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAler}
        autoHideDuration={4000}
        onClose={handleClosAlert}
      >
        <Alert onClose={handleClosAlert} severity="error" sx={{ width: "100%" }}>
          Llene los campos requeridos.
        </Alert>
      </Snackbar>
    </React.Fragment >
  );
}
