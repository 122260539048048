import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import exterior1 from "../images/blackMica.png";
import exterior2 from "../images/whiteDiamond.png";
import exterior3 from "../images/titaniumGrey.png";
import exterior4 from "../images/sterlingSilver.png";
import exterior5 from "../images/blackDiamond.png";
import exterior6 from "../images/cosmicBlue.png";
import exterior7 from "../images/redDiamond.png";
import Frente from '../images/FrentePHEV.png'
import Typography from '../components/Typography';



import { EffectCoverflow, Navigation } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};


function ProductValues() {
  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        marginTop: "-10px",

        overflow: "hidden",
        background:
          " linear-gradient(117deg, rgba(51,48,51,1) 0%, rgba(23,21,21,1) 19%, rgba(21,21,21,1) 61%, rgba(21,21,21,1) 84%, rgba(39,39,40,1) 100%)"
      }}
    >
      <Grid
        container
        style={{
          marginBottom: "80px",
          display: "flex",
          marginTop: "100px",

          position: "relative",
          placeContent: "flex-start",
        }}
      >
        <Grid item xs={12} sm={12}>
          <Typography style={{
            fontFamily: "mmcmedium",
            fontSize: "clamp(1.8rem, 6vw, 2.8rem)",

          }} align="center" component="h1" color="white">
            Colores
          </Typography>
        </Grid>
        {/* <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
        /> */}
        <Grid container sx={{ marginBottom: "90px" }}>
          <Swiper
            navigation={true}
            slidesPerView={1}
            loop={true}
            centeredSlides={true}
            effect={"coverflow"}
            spaceBetween={isMobile() ? 650 : 320}
            modules={[Navigation, EffectCoverflow]}

            className="mySwiper"
            coverflowEffect={{
              rotate: 0,
              stretch: 500,
              depth: 0,
              modifier: 1,
              slideShadows: false,
            }}
          >
            <SwiperSlide
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: "22px",
                  fontFamily: "mmcregular",
                  marginBottom: "20px",
                }}
              >
                Black Mica
              </div>
              <Box
                component="img"
                src={exterior1}
                alt="exterior1"
                sx={{ maxWidth: '75%' }}
              />
            </SwiperSlide>
            <SwiperSlide
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: "22px",
                  fontFamily: "mmcregular",
                  marginBottom: "20px",
                }}
              >
                White Diamond
              </div>
              <Box
                component="img"
                src={exterior2}
                alt="exterior2"
                sx={{ maxWidth: '75%' }}
              />
            </SwiperSlide>
            <SwiperSlide
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: "22px",
                  fontFamily: "mmcregular",
                  marginBottom: "20px",
                }}
              >
                Titanium Grey
              </div>
              <Box
                component="img"
                src={exterior3}
                alt="exterior3"
                sx={{ maxWidth: '75%' }}
              />
            </SwiperSlide>
            <SwiperSlide
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: "22px",
                  fontFamily: "mmcregular",
                  marginBottom: "20px",
                }}
              >
                Sterling Silver
              </div>
              <Box
                component="img"
                src={exterior4}
                alt="exterior4"
                sx={{ maxWidth: '75%' }}
              />
            </SwiperSlide>
            <SwiperSlide
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: "22px",
                  fontFamily: "mmcregular",
                  marginBottom: "20px",
                }}
              >
                Black Diamond
              </div>
              <Box
                component="img"
                src={exterior5}
                alt="exterior5"
                sx={{ maxWidth: '75%' }}
              />
            </SwiperSlide>

            <SwiperSlide
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: "22px",
                  fontFamily: "mmcregular",
                  marginBottom: "20px",
                }}
              >
                Red Diamond
              </div>
              <Box
                component="img"
                src={exterior7}
                alt="exterior7"
                sx={{ maxWidth: '75%' }} />
            </SwiperSlide>
          </Swiper>
        </Grid>


      </Grid>
    </Box>
  );
}

export default ProductValues;
