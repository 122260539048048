//import * as React from 'react';
import React, { useState, useEffect } from "react";
import Button from '../components/Button';
import Typography from '../components/Typography';
import Grid from "@mui/material/Grid";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import axios from "axios";

export default function Form(props) {

	const { set, typeDialog, setTypeDialog, isMenuOption, currentUrl } = props;
	const [privacidad, setPrivacidad] = React.useState(false);
	const [terminos, setTerminos] = React.useState(false);
	const [open, setOpen] = useState(false);
	const [validar, setValidar] = React.useState(false);
	const [datadealers, setDataDealers] = useState([]);
  const [dataOthers, setDataOthers] = useState([]);
	const [valueGenero, setValueGenero] = React.useState('FEMENINO');
	const [valueTiempo, setValueTiempo] = React.useState('0 A 3 MESES');
	const [asentamiento, setAsentamiento] = useState([]);
	const [openAler, setOpenAlert] = React.useState(false);
	
	const [disabledButton, setDisabledButton] = React.useState(false);
  const [sending, setSending] = React.useState(false);
	
	const [dataForm, setDataForm] = useState({

		nombre: "",
		apellidoPa: "",
		apellidoMa: "",
    alias: "",
		numTelefono: '',
		email: '',
		CodigoP: '',
		colonia: '',
		municipio: [],
		calle: '',
		numExt: '',
		numInt: '0',
		distribuidor: "",

	  })

    useEffect(() => {
      const loadScriptByURL = (id, url, callback) => {
        const isScriptExist = document.getElementById(id);
     
        if (!isScriptExist) {
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.src = url;
          script.id = id;
          script.onload = function () {
            if (callback) callback();
          };
          document.body.appendChild(script);
        }
     
        if (isScriptExist && callback) callback();
      }
     
      // load the script by passing the URL
      loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`, function () {
        console.log("Script loaded!");
      });
    }, []);
	
  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };



  const handleChangeGenero = (event) => {
    setValueGenero(event.target.value);
  };
  const handleChangeTiempo = (event) => {
    setValueTiempo(event.target.value);
  };


  const handleChangePrivacidad = (event) => {
    setPrivacidad(event.target.checked)
  }
  const handleChangeTerminos = (event) => {
    setTerminos(event.target.checked)
  }


  const handleClickOpen = (type) => {
    //console.log("sdsss", type)
    setPrivacidad(false)
    setTerminos(false)
    setDataForm({
      nombre: "",
      apellidoPa: "",
      apellidoMa: "",
      alias: "",
      numTelefono: '',
      email: '',
      CodigoP: '',
      colonia: '',
      municipio: [],
      calle: '',
      numExt: '',
      numInt: '0',
      distribuidor: "",
      edad: ""

    })
    setTypeDialog(type)
    setOpen(true);
  };

  const handleClose = () => {
    setValidar(false)
    setOpen(false);
  };


  const validarCodigo = async () => {
    setDataDealers([]);
    setDataOthers([]);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/search_cp/${dataForm.CodigoP}`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
      });

      if (!response) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response.json();
      handleDistribuidor(result.data_location.estado)
      //console.log("response", result);
      setAsentamiento(result.data_location.asentamiento)
      setDataForm({
        ...dataForm,
        municipio: result.data_location.municipio

      }
      )
    } catch (err) {
      console.log(err);
    }
  }
  
  const hasStock = async () => {
    
    try {
      //console.log(process.env.REACT_APP_BACKEND_URL);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/status_stock`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
      });

      if (!response) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response.json();
      
      //console.log("response", result.status);
	  setDisabledButton(result.status);
      return result.status;
    } catch (err) {

      console.log(err);
	  setDisabledButton(false);
	  return false;
    }
  }
  
  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const handleDistribuidor = (estado) => {

    //let nameestado = removeAccents(estado)
    //const procesado = nameestado.replace(/\s+/g, '')  // > "Textodeejemplo"

    let url = `${process.env.REACT_APP_BACKEND_URL}/api/get_dealers/${estado.toUpperCase()}`;
    const config = {
      headers: {
        Accept: " application/json",

      },
    };

    axios
      .get(url, null, config, {
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
		//console.log("response dist", response)
        if (response.data.error) {
          setDataDealers([]);
          setDataOthers([]);
          alert(response.data.error.avaible)
        } else {
          setDataDealers(response.data.data_dealers);
          setDataOthers(response.data.data_other);
        }
      })
      .catch((error) => {
        // Error 😨
        alert("error")
        console.log(error);
      });

  }
  function esNumero(text) {
    if (text) {
      var reg = new RegExp("[0-9]+$");
      if (reg.test(text)) {
        return reg.test(text);
      } else {
        return false;
      }
    }
    return false;
  }
  const removerCaracteres = (event) => {
    let phone = event.target.value;
    if (phone) {
      let wordArray = phone.split("");
      let result = "";
      if (wordArray) {
        wordArray.map((item, i) => {
          if (esNumero(wordArray[i])) {
            result += wordArray[i];
          }
        });
        return result;
      } else {
        return false;
      }
    }
    return false;
  };


  function handleInput(event) {
    //console.log("event", event.target.name)
    if (event.target.name == "numTelefono" || event.target.name == "CodigoP") {
      let resultado = removerCaracteres(event);
      setDataForm({
        ...dataForm,
        [event.target.name]: !resultado ? "" : resultado,
      });
      return false;
    }
    setDataForm({
      ...dataForm,
      [event.target.name]: event.target.value,
    });
  }

  const handleChange = (event) => {

    setDataForm({
      ...dataForm,
      colonia: event.target.value,
    });
  };

  const handelChangeDis = (event) => {
    //console.log("VALUE", event.target.value)
    setDataForm({
      ...dataForm,
      distribuidor: event.target.value,
    });
  }
  const handelChangeEdad = (event) => {
    //console.log("VALUE", event.target.value)
    setDataForm({
      ...dataForm,
      edad: event.target.value,
    });
  }							   

  //console.log("data122", dataForm)

  const handleLeads = () => {
    setSending(true);
    let data = {}
    window.scrollTo(0, 0)

    let url = ``
	
	
	
	
	// gtag('event', 'registro_completo_tag', {
	  // 'correo': dataForm.email, 'edad': dataForm.edad, 'distribuidor': dataForm.distribuidor, 'genero': valueGenero
	// });

    if (typeDialog == "info") {
      url = `${process.env.REACT_APP_BACKEND_URL}/api/create_lead`;
		// window.dataLayer.push({
		// 'event': 'registro_completo_layer',
		// 'correo': dataForm.email,
		// 'edad': dataForm.edad,
		// 'distribuidor': dataForm.distribuidor,
		// 'genero': valueGenero,
		// 'tiempocompra': 'NA'
		// });
      data = {
        "lead_distribuidor": dataForm.distribuidor,
        "lead_nombre": dataForm.nombre,
        "lead_apellido_paterno": dataForm.apellidoPa,
        "lead_apellido_materno": dataForm.apellidoMa,
        "lead_alias": dataForm.alias,
        "lead_num_cel": dataForm.numTelefono,
        "lead_email": dataForm.email,
        "lead_genero": valueGenero,
        "lead_estimado_compra": valueTiempo,
        "lead_edad": dataForm.edad,
        "lead_aviso_privacidad": "on",
        "lead_terminos_condiciones": "on",
        "lead_url": currentUrl
      }
    }
    else {
      url = `${process.env.REACT_APP_BACKEND_URL}/api/create_presale`;
		// window.dataLayer.push({
		// 'event': 'registro_completo_layer',
		// 'correo': dataForm.email,
		// 'edad': dataForm.edad,
		// 'distribuidor': dataForm.distribuidor,
		// 'genero': 'NA',
		// 'tiempocompra': 'NA'
		// });
      data =
      {
        "presale_distribuidor": dataForm.distribuidor,
        "presale_nombre": dataForm.nombre,
        "presale_apellido_paterno": dataForm.apellidoPa,
        "presale_apellido_materno": dataForm.apellidoMa,
        "presale_alias": dataForm.alias,
        "presale_num_cel": dataForm.numTelefono,
        "presale_email": dataForm.email,
        "presale_codigo_postal": dataForm.CodigoP,
        "presale_colonia": dataForm.colonia,
        "presale_municipio": dataForm.municipio,
        "presale_calle": dataForm.calle,
        "presale_num_int": dataForm.numInt,
        "presale_num_ext": dataForm.numExt,
        "presale_aviso_privacidad": "on",
        "presale_terminos_condiciones": "on",
        "presale_url": currentUrl
      }

    }


    const config = {
      headers: {
        Accept: " application/json",
      },
    };
    //console.log("sdaDATA", data)
    if (disableBotom == true) {
      setValidar(true)
      setOpenAlert(true)
      setSending(false);
    } else {

      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'submit' }).then(token => {
          data["g-recaptcha-response"] = token;
          //console.log("g-recaptcha-response");
          //console.log(data);
          axios
          .post(url, data, config, {
            // receive two parameter endpoint url ,form data
          })
          .then((response) => {
            //console.log(response);
            //console.log(typeDialog == "info" ? response.data.lead_data.lead_id : response.data.presale_data.presale_id);
            //console.log(typeDialog == "info" ? "table_leads" : "table_presales");
            // Respuesta del servidor
            // alert("se envio con exito")
            setOpen(false)
            set(true)
            setSending(false);
            window.dataLayer.push({
              'event': 'registro_completo_layer',
              'url': currentUrl,
              'lead_id': typeDialog == "info" ? response.data.lead_data.lead_id : response.data.presale_data.presale_id,
              'lead_tipo' : typeDialog == "info" ? "table_leads" : "table_presales",
              'correo': dataForm.email,
              'edad': dataForm.edad,
              'distribuidor': dataForm.distribuidor,
              'genero': typeDialog == "info" ? valueGenero : 'NA',
              'tiempocompra': 'NA'
            });
          })
          .catch((error) => {
            // Error 😨
            alert("error")
            console.log(error);
            setSending(false);
          });
        });
      });
    }
  }
  const handleClosAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };


  let disableBotom = true;


  if (typeDialog == "info") {
	  
    if (
      dataForm.nombre.length >= 2 &&
      dataForm.apellidoPa.length >= 2 &&
      dataForm.alias.length >= 1 &&
      dataForm.numTelefono.length == 10 &&
      dataForm.edad.length >= 1 &&
      dataForm.CodigoP.length >= 4 &&
      dataForm.distribuidor &&
      privacidad == true &&
	  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(dataForm.email) &&
      terminos == true
    ) {
      disableBotom = false

    }
  } else {
    if (
      dataForm.nombre.length >= 2 &&
      dataForm.apellidoPa.length >= 2 &&
      dataForm.alias.length >= 1 &&
      dataForm.numTelefono.length == 10 &&
      dataForm.CodigoP.length >= 4 &&
      dataForm.distribuidor &&
      dataForm.calle.length >= 2 &&
      dataForm.municipio.length >= 2 &&
      dataForm.numExt.length >= 1 &&
      dataForm.colonia.length >= 2 &&
	  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(dataForm.email) &&
      terminos == true
      &&

      privacidad == true

    ) {
      disableBotom = false

    }

  }
  hasStock();

  return (
	
    <>
      {isMenuOption ? (
        <Link sx={{ fontSize: 12, fontFamily: "mmcregular", color: "white" }}
        href="#" onClick={() => {
          handleClickOpen("aparta");
        }}>APÁRTALA AQUÍ</Link>
      ) : (
      <div className="" sx={{ alignItems: "center", textAlign:"center"}}>
        
        
        

       

            <>
            

            <Grid container spacing={2}
              marginTop={16}
              direction="row"
              justifyContent="center"
              alignItems="center">
              <Grid container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              md={8}
              lg={8}
              >
                <Button
                  onClick={() => {
                    handleClickOpen("info");
                  }}
                  variant="contained"
                  size= "medium"
                  color="primary"
          disabled = { !disabledButton  }
                  style={{
                    width: '290px',
                    height: '65px',
                    background: "#ffffff",
                    borderRadius: "10px",
                    fontFamily: "mmcregular",
                    fontSize: "clamp(1rem, 4vw, 1rem)",
                    letterSpacing: "0.1em",
                    lineHeight: "1em",
                    color: "#000000",
                    marginBottom:"32px",
          display: disabledButton ? 'block' : 'none'
                  }}
                >
                  SUSCRÍBETE
                </Button>
                <Button
                  onClick={() => {
                    handleClickOpen("aparta");
                  }}
                  variant="contained"
                  size= "medium"
                  color="primary"
          disabled = { !disabledButton  }
                  style={{
                    width: '290px',
                    height: '65px',
                    background: "#cf0303",
                    borderRadius: "10px",
                    fontFamily: "mmcregular",
                    fontSize: "clamp(1rem, 4vw, 1rem)",
                    letterSpacing: "0.1em",
                    lineHeight: "1em",
                    marginBottom:"32px",
          display: disabledButton ? 'block' : 'none'
                  }}
                >
                  APÁRTALA
                </Button>
              </Grid>

              {/* <Typography style={{
                    fontSize: "clamp(1em, 5vw, 1.2rem)",
                    marginTop: "10px",
                    color: "white",
                    textAlign: "center",
                    alignContent: "center",
                    marginBottom: "5px",
                    fontFamily: "mmcregular",
                    letterSpacing: "4",
                    lineHeight:"1em",
                    display: disabledButton ? 'block' : 'none'
                  }}>
              o
              </Typography> */}

              {/* <Grid container
              direction="row"
              justifyContent="center"
              alignItems="center">
                <Button
                  onClick={() => {
                    handleClickOpen("info");
                  }}
                  variant="contained"
                  size= "medium"
                  style={{
                    width: '290px',
                    height: '65px',
                    background: "transparent",
                    borderRadius: "10px",
                    fontFamily: "mmcregular",
                    fontSize: "clamp(1rem, 4vw, 1rem)",
                    letterSpacing: "0.1em",
                    lineHeight: "1em",
                    color: "#cf0303",
                    textDecoration: "underline"
                  }}
                >
                  CONOCE MÁS
                </Button>
              </Grid> */}
            </Grid>
            </>
					


      </div>
      )}
    <Dialog
			fullWidth={true}
			maxWidth='md'
			open={open}
			onClose={handleClose}>
			<DialogTitle style={{
          fontFamily: "mmcoffice",
          fontSize: 25,
          lineHeight:"1em",
          marginTop: "15px",

        }}>
          <center>
            {typeDialog == "aparta" ? "APÁRTALA AQUÍ" : "Más Información"}
          </center>
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}

            sx={{
              position: 'absolute',
              right: 8,
              top: 5,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
			<DialogContent>
      <Typography style={{
                    fontSize: "clamp(1em, 5vw, 1.4rem)",
                    //marginTop: "-15px",
                    color: "red",
                    textAlign: "center",
                    alignContent: "center",
                    marginBottom: "40px",
                    marginTop: "0px",
                    fontFamily: "mmcoffice",
                    letterSpacing: "4",
                    lineHeight:"2em"
                  }}>
          <center>
            {typeDialog == "aparta" ? "Con $20,000.00 MXN" : "Déjanos tus datos para ser de los primeros en recibir más información."}
          </center>
        </Typography>
			  {/* <DialogContentText style={{
				fontFamily: "mmcregular",
				fontSize: 20,
			  }}>
				Llena el formulario para poder apartar la nueva Mitsubishi Outlander PHEV
			  </DialogContentText> */}
			  <Grid container spacing={5}>
				<Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
				  <TextField
					inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
					InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
					/*autoFocus*/
          autoFocus
					onChange={handleInput}
					value={dataForm.nombre}
					margin="dense"
					name="nombre"
					label="NOMBRE(S)*"
					fullWidth
					variant="standard"
					error={validar == false || validar == false || dataForm.nombre.length > 2 ? false : true}
					helperText="El campo es requerido*"

				  />
				</Grid>
				<Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
				  <TextField
					inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
					InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
					/*autoFocus*/
					onChange={handleInput}
					value={dataForm.apellidoPa}
					margin="dense"
					name="apellidoPa"
					label="APELLIDO PATERNO*"
					type="text"
					fullWidth
					variant="standard"
					error={validar == false || dataForm.apellidoPa.length > 2 ? false : true}
					helperText="El campo es requerido*"
				  />
				</Grid>
				<Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
				  <TextField
					inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
					InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
					/*autoFocus*/
					onChange={handleInput}
					value={dataForm.apellidoMa}

					margin="dense"
					name="apellidoMa"
					label="APELLIDO MATERNO"
					type="text"
					fullWidth
					variant="standard"

				  />
				</Grid>
        <Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
				  <TextField
					inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
					InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
					/*autoFocus*/
					onChange={handleInput}
					value={dataForm.alias}
					margin="dense"
					name="alias"
					label="ALIAS*"
					type="text"
					fullWidth
					variant="standard"
          error={validar == false || dataForm.alias.length > 1 ? false : true}
          helperText="Elige un alias de como te gustaría que te identitiquemos. El campo es requerido*"

				  />
				</Grid>
				<Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
				  <TextField
					inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
					InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
					/*autoFocus*/
					onChange={handleInput}
					value={dataForm.email}

					margin="dense"
					name="email"
					label="CORREO ELECTRÓNICO*"
					type="email"
					fullWidth
					variant="standard"
					error={validar == false || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(dataForm.email) ? false : true}
					helperText="El campo es requerido*"
				  />
				</Grid>

				<Grid item xs={12} sm={typeDialog == "aparta" ? 6 : 6} style={{"paddingTop": "10px"}}>
				  <TextField
					inputProps={{ maxLength: 10, style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
					InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
					/*autoFocus*/
					onChange={handleInput}
					value={dataForm.numTelefono}

					error={validar == false || dataForm.numTelefono.length > 9 ? false : true}
					helperText="El campo es requerido*"
					margin="dense"
					name="numTelefono"
					label="NÚMERO DE TELÉFONO*"
					type="email"
					fullWidth
					variant="standard"
				  />
				</Grid>
				{typeDialog == "info" ? (
				  <Grid item xs={12} sm={3} style={{"paddingTop": "10px"}}>
					<FormControl variant="standard" fullWidth>
							<InputLabel sx={{ fontSize: 20, fontFamily: "mmcregular", }} id="demo-simple-select-standard-label" error={validar == false || dataForm.edad.length > 1 ? false : true}>EDAD</InputLabel>
								<Select
								  labelId="demo-simple-select-standard-label"
								  id="demo-simple-select-standard"
								  value={dataForm.edad}
								  onChange={handelChangeEdad}
								  label="EDAD"
                  error={validar == false || dataForm.edad.length > 1 ? false : true}
								>
									<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"18 a 24 años"}>{"18 a 24 años"}</MenuItem>
									<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"25 a 31 años"}>{"25 a 31 años"}</MenuItem>
									<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"32 a 38 años"}>{"32 a 38 años"}</MenuItem>
									<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"39 a 45 años"}>{"39 a 45 años"}</MenuItem>
									<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"46 a 52 años"}>{"46 a 52 años"}</MenuItem>
									<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"53 a 59 años"}>{"53 a 59 años"}</MenuItem>
									<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"60 o más"}>{"60 o más"}</MenuItem>
								</Select>
								 
								  
								  
							   
										
								<FormHelperText error={validar == false || dataForm.edad.length > 1 ? false : true}>El campo es requerido*</FormHelperText>
							  </FormControl>

				  </Grid>
				) : ""}

				<Grid item xs={12} sm={typeDialog == "aparta" ? 6 : 3} style={{"paddingTop": "0px"}}>
				  <TextField
					inputProps={{ maxLength: 5, style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
					InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
					/*autoFocus*/
					onChange={handleInput}


					value={dataForm.CodigoP}

					margin="dense"
					name="CodigoP"
					label="CÓDIGO POSTAL*"
					type="email"
					error={validar == false || dataForm.CodigoP.length > 3 ? false : true}
					helperText="El campo es requerido*"
					fullWidth

					onBlur={() => {
					  validarCodigo();
					}}
					variant="standard"
				  />
				</Grid>
				<Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
				  <FormControl variant="standard" fullWidth>
					<InputLabel
					  sx={{ fontSize: 20, fontFamily: "mmcregular", }}
					  error={validar == false || dataForm.distribuidor.length > 2 ? false : true}

					  id="demo-simple-select-standard-label">DISTRIBUIDOR</InputLabel>
					<Select
					  labelId="demo-simple-select-label"
					  id="demo-simple-select"
					  input={<NativeSelect sx={{ fontSize: 20, fontFamily: "mmcregular", }} label="Tag" />}

					  value={dataForm.distribuidor}
					  label="DISTRIBUIDOR"
					  error={validar == false || dataForm.distribuidor.length > 2 ? false : true}
					  onChange={handelChangeDis}

					>
					  {datadealers.map((item) => {
						  return (

							<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }}
							  value={item.dealer_code}>{item.delaer_name}</MenuItem>
						  )

					  })}
            <Divider>Otros distribuidores</Divider>
            {dataOthers.map((item) => {
						  return (

							<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }}
							  value={item.dealer_code}>{item.dealer_state} - {item.delaer_name}</MenuItem>
						  )

					  })}

					</Select>
					<FormHelperText error={validar == false || dataForm.distribuidor.length > 2 ? false : true}>El campo es requerido*</FormHelperText>

				  </FormControl>
				</Grid>
				{typeDialog == "aparta" ? (
				  <><Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
					<FormControl variant="standard" fullWidth>
					  <InputLabel
						sx={{ fontSize: 20, fontFamily: "mmcregular", }}
            error={validar == false || dataForm.colonia.length > 2 ? false : true}
						id="demo-simple-select-standard-label">COLONIA</InputLabel>
					  <Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						input={<NativeSelect sx={{ fontSize: 20, fontFamily: "mmcregular", }} label="Tag" />}
            error={validar == false || dataForm.colonia.length > 2 ? false : true}
						helperText="El campo es requerido*"
						value={dataForm.colonia}
						label="COLONIA"
						onChange={handleChange}
					  >
						{asentamiento.map((item) => (
						  <MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }}
							value={item}>{item}</MenuItem>
						))}

					  </Select>
            <FormHelperText error={validar == false || dataForm.colonia.length > 2 ? false : true}>El campo es requerido*</FormHelperText>
					</FormControl>
				  </Grid><Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
					  <TextField
						inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
						InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
						/*autoFocus*/
						onChange={handleInput}
						value={dataForm.municipio}
						margin="dense"
						name="municipio"
						label="MUNICIPIO ALCALDÍA*"
						type="email"
						fullWidth
						error={validar == false || dataForm.municipio.length > 2 ? false : true}
						helperText="El campo es requerido*"
						variant="standard" />
					</Grid><Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
					  <TextField
						inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
						InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
						/*autoFocus*/
						onChange={handleInput}
						value={dataForm.calle}

						margin="dense"
						name="calle"
						label="CALLE*"
						type="email"
						error={validar == false || dataForm.calle.length > 2 ? false : true}
						helperText="El campo es requerido*"
						fullWidth
						variant="standard" />
					</Grid><Grid item xs={6} sm={3} style={{"paddingTop": "10px"}}>
					  <TextField
						inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
						InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
						/*autoFocus*/
						onChange={handleInput}
						value={dataForm.numExt}

						margin="dense"
						name="numExt"
						label="NO. EXT.*"
						type="email"
						error={validar == false || dataForm.numExt.length >= 1 ? false : true}
						helperText="El campo es requerido*"
						fullWidth
						variant="standard" />
					</Grid>
					<Grid item xs={6} sm={3} style={{"paddingTop": "10px"}}>
					  <TextField
						inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
						InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
						/*autoFocus*/
						onChange={handleInput}
						value={dataForm.numInt}

						margin="dense"
						name="numInt"
						label="NO. INT."
						type="email"
						fullWidth
						variant="standard" />
					</Grid></>
				) : ""}
				{typeDialog == "info" ? (
				  <Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
					<FormControl>
					  <FormLabel sx={{ fontSize: 20, fontFamily: "mmcbold", color: "black" }} id="demo-radio-buttons-group-label">GÉNERO</FormLabel>
					  <RadioGroup
						aria-labelledby="demo-radio-buttons-group-label"
						name="radio-buttons-group"
						value={valueGenero}
						onChange={handleChangeGenero}
					  >
						<FormControlLabel value="FEMENINO" control={<Radio />} label="Femenino" />
						<FormControlLabel value="MASCULINO" control={<Radio />} label="Masculino" />
						<FormControlLabel value="OTRO" control={<Radio />} label="Otro" />
					  </RadioGroup>
					</FormControl>
				  </Grid>
				) : ""}
				{typeDialog == "info" ? (
				  <Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
					<FormControl>
					  <FormLabel sx={{ fontSize: 20, fontFamily: "mmcbold", color: "black" }} id="demo-radio-buttons-group-label">TIEMPO ESTIMADO DE COMPRA</FormLabel>
					  <RadioGroup
						aria-labelledby="demo-radio-buttons-group-label"
						defaultValue="0 a 3 meses"
						name="radio-buttons-group"
						value={valueTiempo}
						onChange={handleChangeTiempo}
					  >
						<FormControlLabel value="0 a 3 meses" control={<Radio />} label="0 a 3 meses" />
						<FormControlLabel value="3 a 6 meses" control={<Radio />} label="3 a 6 meses" />
						<FormControlLabel value="Más de 6 meses" control={<Radio />} label="Más de 6 meses" />
						<FormControlLabel value="Sin interés" control={<Radio />} label="Sin interés" />

					  </RadioGroup>
					</FormControl>
				  </Grid>
				) : ""}



				<Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
				  <FormControl component="fieldset">
					<FormLabel sx={{ fontSize: 20, fontFamily: "mmcbold", color: validar == false || privacidad == true ? 'false' : '#f44336' }} component="legend">AVISO DE PRIVACIDAD *</FormLabel>
					<FormGroup aria-label="position" row>

					  <FormControlLabel
						sx={{ fontSize: 17, fontFamily: "mmcregular", color: validar == false || privacidad == true ? 'false' : '#f44336' }}
						checked={privacidad}
						onChange={handleChangePrivacidad}
						//value="end"
						control={<Checkbox />}
						label={
						  <Link sx={{ fontSize: 16, fontFamily: "mmcregular", color: validar == false || privacidad == true ? 'false' : '#f44336'}}
							href="https://www.mitsubishi-motors.mx/legal/aviso-de-privacidad" target="_blank">He leído y acepto el aviso de privacidad</Link>} labelPlacement="end"

					  />

					</FormGroup>
				  </FormControl>
				</Grid>
				<Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
				  <FormControl component="fieldset">
					<FormLabel sx={{ fontSize: 20, fontFamily: "mmcbold", color: validar == false || terminos == true ? 'false' : '#f44336' }} component="legend">TÉRMINOS  Y CONDICIONES *</FormLabel>
					<FormGroup aria-label="position" row>

					  <FormControlLabel
						sx={{ fontSize: 17, fontFamily: "mmcregular", color: validar == false || terminos == true ? 'false' : '#f44336' }}
						checked={terminos}
						onChange={handleChangeTerminos}

						value="end"
						control={<Checkbox />}
						label={
						  <Link sx={{ fontSize: 16, fontFamily: "mmcregular", color: validar == false || terminos == true ? 'false' : '#f44336' }}
							href="https://www.mitsubishi-motors.mx/legal/terminos-y-condiciones" target="_blank">Estoy de acuerdo con el propósito de uso de datos personales</Link>} labelPlacement="end"
					  />

					</FormGroup>
				  </FormControl>
				</Grid>

				<Grid item xs={12} sm={12}>
				  <center>
					<Button
            disabled = {sending}
					  onClick={() => {
						handleLeads();
					  }}
					  variant="contained"
					  size="large"
					  // disabled={disableBotom}
					  color="primary"
					  style={{

						width: '300px',
						height: '65px',
						background: sending ? "#ccc" : "#e32515",
						borderRadius: "15px",
						fontFamily: "mmcbold",
						fontSize: 28,
					  }}
					>
					  ENVIAR
					</Button>
				  </center>

				</Grid>


			  </Grid>
			</DialogContent>

		  </Dialog>
    </>


  )
}
