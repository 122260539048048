import * as React from "react";

import ProductHeroLayout from './ProductHeroLayout';
import axios from "axios";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ProductCTA from '../../modules/views/ProductCTA';

import phev from '../images/l200_logo.png'


import tenkiu from '../images/tenkiu.png'



import Frente from '../images/ultima2.png'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useSearchParams
} from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import AppAppBar from '../../modules/views/AppAppBar';
import AppFooter from '../../modules/views/AppFooter';


// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

const item = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    px: 5,
};
export default function ProductHero() {
   



    return (
        <React.Fragment>
            <AppAppBar />
            <Grid container direction="row"
                justifyContent='center'
                alignItems="center"
                sx={{ marginTop: "95px",
                    background:
                        " linear-gradient(48deg, rgba(37,37,37,1) 0%, rgba(21,21,21,0.989233193277311) 8%, rgba(21,21,21,1) 52%, rgba(21,21,21,1) 66%, rgba(61,60,63,0.9752275910364145) 97%)"
                }}
            >
                <Grid item xs={12} sm={12} md={12} sx={{ marginLeft: "45px" }}  >
                    
                        <Typography style={{
                            fontFamily: "mmcoffice",
                            fontSize: "clamp(0.4rem, 4vw, 1.8rem)",
                        }} align="left" component="h1" color="white"><br/>
                            
                            Términos y condiciones de la preventa<br/>

                        </Typography>

                        <Typography style={{
                            fontFamily: "mmcregular",
                            fontSize: "clamp(0.4rem, 4vw, 1.2rem)",
                        }} align="left" component="h1" color="white"><br/>

MITSUBISHI MOTORS DE MÉXICO, S.A. DE C.V. (en lo sucesivo, “MMDM”), lo invita a participar en la preventa exclusiva de nuestro modelo Outlander PHEV 2023 (en adelante, el “Programa de Preventa”). Por favor lea los términos y condiciones del Programa de Preventa cuidadosamente antes de efectuar el pago de su apartado y comprar el modelo Outlander PHEV 2023 en preventa. La compra de este producto en preventa confirma irrevocablemente su aceptación incondicional de los términos y condiciones siguientes: <br/>

<br/>
<span style={{fontWeight: "bold"}}>I.- Términos y condiciones:</span> <br/><br/>

Generales del Programa de Preventa:<br/><br/>
<ul>
<li>Unidades disponibles para preventa: 30 (treinta)</li>
<li>Version Limited Precio: desde $[999,000] pesos ([00/100] PESOS M.N.), más I.V.A. aplicable.</li>
<li>Vesión Diamond.</li>
<li>Precio:  desde $[1,058,500] pesos ([00/100] PESOS M.N.), más I.V.A. aplicable.</li>
<li>Apartado: $40,000.00 pesos (CUARENTA MIL PESOS 00/100 M.N.)</li>
<li>Plataforma para pago: Openpay By BBVA</li>
<li>Entrega: a partir de 1º de junio 2023.</li>
<li>Fecha máxima de entrega: 30 de agosto 2023.</li>
<li>Cancelación: Solicitud por escrito al Distribuidor autorizado MMDM dentro 30 (treinta) días naturales inmediatos siguientes a la fecha de pago del apartado.</li>
<li>Devolución: Tras la cancelación, en un plazo de 15 (quince) días naturales. </li>

<li>Versiones y colores sujetos a disponibilidad: 

<ul>
 <li>
 *Limited:
Titanium Gray Metallic
Sterling Silver Metallic
Black Mica
Red Diamond
</li>

 <li>
 *Diamond:
White Diamond
</li>

</ul>

</li>
</ul>


Específicas del Programa de Preventa:<br/><br/>

1. Para realizar el registro, el cliente deberá ingresar a la sección “Apártala aquí” dentro de la página web www.mitsubishistore.mitsubishi-motors.mx
Con este registro el cliente tendrá la opción de reservar hasta un máximo de 1 (una) unidad una Outlander PHEV 2023 de las 30 (treinta) disponibles en el Programa de Preventa. 
<br/><br/>
2. Para realizar el registro, el cliente deberá ingresar la siguiente información:<br/><br/>

<ul>
<li>Tipo de identificación.</li>
<li>Número de Identificación.</li>
<li>Nombres y apellidos.</li>
<li>Correo electrónico.</li>
<li>Teléfono fijo y teléfono celular. </li>
<li>Dirección de residencia (Calle, No. Interior y exterior, Colonia, Municipio, Código Postal).</li>
<li>Ciudad de residencia. </li>
<li>Distribuidor seleccionado.</li>
<li>Aviso de Privacidad MMDM.</li>
<li>Términos y Condiciones MMDM.</li>
<li>Concepto (datos para Openpay by BBVA)</li>
<li>Importe. (datos para Openpay by BBVA)</li>
<li>Nombre del titular de la tarjeta (datos para Openpay by BBVA).</li>
<li>Tarjeta de débito o crédito (datos para Openpay by BBVA).</li>
<li>No. De tarjeta (datos para Openpay by BBVA).</li>
<li>Fecha y expiración: mes y año (datos para Openpay by BBVA).</li>
<li>Código de seguridad (datos para Openpay by BBVA).</li>
<li>Aviso de Privacidad (Openpay by BBVA).</li>
<li>Términos y condiciones (Openpay by BBVA).</li>
<li>Banco, sucursal, cuenta bancaria y CLABE para devoluciones (datos para Openpay by BBVA). </li>
</ul>

3. Una vez ingresados los datos anteriores, el cliente deberá aceptar los Términos y Condiciones, y autorizar el tratamiento de sus datos personales de conformidad con el aviso de privacidad a disposición del cliente para su consulta en https://www.mitsubishi-motors.mx/legal/aviso-de-privacidad , y dar “clic” a “Apártala aquí” en la misma página web. 
<br/><br/>
4. Después de que el cliente realice el registro y apartado, y sea verificado, será contactado, en horario hábil de 9:00 AM a 6:00 pm por un asesor del Distribuidor MMDM que haya seleccionado, a fin de realizar la validación de los datos. 
<br/><br/>
5. En caso de que el cliente continúe con el proceso, a fin de gozar del beneficio del Programa de Preventa y tener la opción de compra del vehículo, deberá efectuar el pago del apartado a través de la plataforma Openpay By BBVA, ese valor se aplicará al pago del precio total del vehículo.
<br/><br/>
6. Una vez disponibles, el cliente será contactado por un asesor del Distribuidor MMDM que haya seleccionado, a fin de que opte por cualquiera de las siguientes opciones:
<ul>
<li>
Pago del saldo insoluto del precio total, mediante transferencia bancaria a la cuenta del Distribuidor MMDM asignado para la formalización de la venta y entrega del vehículo al cliente, o
</li>
<li>
Financiamiento con 0% (cero por ciento) de comisión por apertura, si el financiamiento se formaliza con Mitsubishi Motors Financial Services.
</li>
</ul>


7. En caso de cancelación, el cliente, dentro de los 30 (días naturales siguientes a la fecha de pago del Apartado, deberá enviar correo electrónico al Distribuidor MMDM que haya sido seleccionado, solicitando dicha devolución, la cual será formalizada dentro los 15 (quince) días naturales siguientes, acreditándose contra la cuenta bancaria que el cliente haya designado al efecto. 
<br/><br/>
8. La entrega de la unidad Outlander PHEV 2023, en la versión y el color escogido por el cliente, estará sujeta a disponibilidad, y se realizará directamente en las instalaciones del Distribuidor MMDM seleccionado por el cliente, previa cita.
<br/><br/>
Particulares de la Oferta y el Programa de Preventa:<br/>

<ul>
<li>Oferta comercial para preventa exclusiva en modelo Outlander PHEV – 2023.</li>
<li>Promoción válida en toda la República Mexicana.</li>
<li>Vigencia del 21 de marzo al 21 de julio de 2023 o hasta agotar existencias.</li>
<li>Esta oferta aplica únicamente para personas físicas (mayores de edad), personas morales y físicas con actividad empresarial y residentes en México. La oferta no aplica con otras promociones u ofertas, ni en la compra de flotillas. 
</li>
</ul>


Para conocer planes, verificar precios, requisitos de contratación, comisiones, disponibilidad, especificaciones, términos y condiciones acude con tu Distribuidor Autorizado Mitsubishi®. 
<br/><br/>

Revisa nuestro Aviso de Privacidad en: https://www.mitsubishi-motors.mx/legal/aviso-de-privacidad
<br/><br/>

CASO FORTUITO O FUERZA MAYOR. MMDM sólo tiene 30 (treinta) unidades disponibles para esta promoción y son exclusivas para apartado del PROGRAMA DE preventa en línea, en caso que durante la llegada a territorio mexicano o durante el traslado de los Vehículos MITSUBISHI® a los Distribuidores autorizados MITSUBISHI®, ÉSTOS sufrieran robos, maltratos, daños, totales o parciales, o bien SE PRESENTE UN caso fortuito, EVENTO DE fuerza mayor O ACTO AJENO AL CONTROL DE MMDM, el Distribuidor AUTORIZADO ASIGNADO avisará AL CLIENTE de dicha situación y entregará el Pago de Anticipo siguiendo lo establecido estos Términos y Condiciones, SIN REPSONSABILIDAD ALGUNA PARA LA MARCA MITSUBISHI®, MMDM Y/O EL DISTRIBUIDOR AUTORIZADO MITSUBISHI®.




                            </Typography>
                            
                        
                    
                </Grid>
            </Grid>
            <ProductCTA />

            <AppFooter />
            

        </React.Fragment >
    );
}
