import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ProTip from './ProTip';
import Home from './containers/Home'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Validarpago from './modules/views/validarPago'
import TerminosyCondiciones from './modules/views/tyc'



function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright ©'}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function App() {


  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/validarpago" element={<Validarpago />} />
        <Route path="/terminosycondiciones" element={<TerminosyCondiciones />} />

      </Routes>
    </Router>
  );
}
