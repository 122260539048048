import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '../components/Button';
import Typography from '../components/Typography';
import Link from '@mui/material/Link';


const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  width: '220px'
};
const item2 = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const number = {
  fontSize: 22,
  fontFamily: 'mmcoffice',
  color: "#cf0303",
  fontWeight: 'medium',

};

const image = {
  height: 55,
  my: 4,
};
const linkcss = {
  fontFamily: 'mmcregular',
  color: "white",
  marginTop: "10px",
  fontSize: 15,


}



function ProductHowItWorks() {
  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  return (
    <Box
      component="section"
      sx={{
        display: 'flex', background:
          "linear-gradient(69deg, rgba(51,48,51,1) 0%, rgba(27,25,26,1) 7%, rgba(23,21,21,1) 22%, rgba(21,21,21,1) 61%, rgba(21,21,21,1) 84%, rgba(39,39,40,1) 100%)"
        ,
      }}
    >
      
    </Box>
  );
}

export default ProductHowItWorks;