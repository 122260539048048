import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import logowhite from '../images/logowhite.png'
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import stylesf from './section.css'
import Form from './Form';

const pages = ['INICIO', 'DISEÑO', 'INTERIOR', 'TECNOLOGÍA', 'APÁRTALA AQUÍ'];

function ResponsiveAppBar(props) {
  const { set, fin, typeDialog, setTypeDialog, isMenuOption, url } = props;
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
	event.stopPropagation();
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
	event.stopPropagation();
  };

   const handleRedirect = (event) => {
	   //event.stopPropagation();
     console.log("eventeded", event)
     if(event== 'MODELOS'){
       window.location.href = ''
     }
   }

  const scrollTo = (div) => {
    if (div == "inicio") {
      set(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      let element = document.getElementById(div);
      element.scrollIntoView({behavior: "smooth"});
    }
  }
	setTimeout(function () {
		var hash = window.location.hash;
		window.location.hash = "";
		window.location.hash = hash;
	}, 300);

  return (
    <AppBar position="absolute" sx={{ background: 'black', textAlign:"center" }} className="top_bar">
     
        <Toolbar disableGutters>

          <Grid sx={{ display: { xs: 'none', md: 'flex' } }}>
		  <Link sx={{ fontSize: 12, fontFamily: "mmcregular", color: "white" }} onClick={() => scrollTo('inicio')}>
            <img 
              style={{ color: "white", margin: '15px' }}
              src={logowhite}
              alt="Mitsubishi_Motors_logo"
              width="70"
              height="68"
            />
			</Link>
          </Grid>
          
          <Grid alignItems="center" sx={{
            display: { xs: 'flex', md: 'none', alignItems: 'center' }, mr: 1, flexGrow: 1, textAlign: "center", alignItems: 'center', justifyContent: 'center'
          }} >
			<Link sx={{ fontSize: 12, fontFamily: "mmcregular", color: "white" }} onClick={() => scrollTo('inicio')}>
            <img className="logo_top"
              style={{ color: "white", marginTop: '15px', marginBottom: '10px', margin: '15px auto' }}
              src={logowhite}
              alt="Mitsubishi_Motors_logo"
              width="70"
              height="68"
            />
			</Link>
          </Grid>


          


          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, }}>

            <Button
              onClick={handleRedirect}
              sx={{ my: 2, color: 'white', display: 'block', fontFamily: 'mmcregular', fontSize: 12, textAlign: "center", margin: '12px' }}
            >
              <Link sx={{ fontSize: 12, fontFamily: "mmcregular", color: "white" }} onClick={() => scrollTo('inicio')}>INICIO</Link>
            </Button>

            {!fin && (
              <>
                <Button
                onClick={() => handleRedirect()}
                sx={{ my: 2, color: 'white', display: 'block', fontFamily: 'mmcregular', fontSize: 12, textAlign: "center", margin: '12px' }}
              >
                <Link sx={{ fontSize: 12, fontFamily: "mmcregular", color: "white" }} onClick={() => scrollTo('diseno')}>DISEÑO</Link>
              </Button>
              <Button
                onClick={() => handleRedirect()}
                sx={{ my: 2, color: 'white', display: 'block', fontFamily: 'mmcregular', fontSize: 12, textAlign: "center", margin: '12px' }}
              >
                <Link sx={{ fontSize: 12, fontFamily: "mmcregular", color: "white" }} onClick={() => scrollTo('manejo')}>MANEJO</Link>
              </Button>
              <Button
                onClick={() => handleRedirect()}
                sx={{ my: 2, color: 'white', display: 'block', fontFamily: 'mmcregular', fontSize: 12, textAlign: "center", margin: '12px' }}
              >
                <Link sx={{ fontSize: 12, fontFamily: "mmcregular", color: "white" }} onClick={() => scrollTo('equipamiento')}>EQUIPAMIENTO</Link>
              </Button>
              <Button
                onClick={() => handleRedirect()}
                sx={{ my: 2, color: 'white', display: 'block', fontFamily: 'mmcregular', fontSize: 12, textAlign: "center", margin: '12px' }}
              >
                <Link sx={{ fontSize: 12, fontFamily: "mmcregular", color: "white" }} onClick={() => scrollTo('trabajo')}>TRABAJO</Link>
              </Button>
              <Button
                onClick={() => handleRedirect()}
                sx={{ my: 2, color: 'white', display: 'block', fontFamily: 'mmcregular', fontSize: 12, textAlign: "center", margin: '12px' }}
              >
                <Form set={set} setTypeDialog={setTypeDialog} typeDialog={typeDialog} isMenuOption={isMenuOption} currentUrl={url}/>
              </Button>
            </>
            )}

          </Box>


        </Toolbar>

        
    </AppBar>
  );
}
export default ResponsiveAppBar;
