import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from '../components/Typography';

import inte1 from '../images/Interiores/inte1.jpg'
import inte2 from '../images/Interiores/inte2.jpg'
import inte3 from '../images/Interiores/inte3.jpg'
import inte4 from '../images/Interiores/inte4.jpg'
import inte5 from '../images/Interiores/inte5.jpg'
import inte6 from '../images/Interiores/inte6.jpg'
import atri1 from '../images/trabajo/trabajo-l200-mitsubishi-01.jpg'
import atri2 from '../images/trabajo/trabajo-l200-mitsubishi-02.jpg'
import atri3 from '../images/trabajo/trabajo-l200-mitsubishi-03.jpg'


import { EffectCoverflow, Navigation } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { Pagination } from "swiper";
import { maxWidth } from "@mui/system";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};


const isMobile = () => {
return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/BlackBerry/i)
);
};

export default function AtributeMobile() {
    return (
        <Grid
        container 
        style={{ marginTop: isMobile()?  '10px' :"40px", marginBottom: "170px", placeContent: "space-around" }}>

            <Grid item xs={10} md={12} lg={12} sx={{ alignItems: "center"}}>
                <Typography style={{
                    fontSize: "clamp(2em, 5vw, 3rem)",
                    marginTop: "0",
                    color: "white",
                    textAlign: "center",
                    alignContent: "center",
                    marginBottom: "60px",
                    fontFamily: "mmcregular",
                    letterSpacing: "4",
                    lineHeight:"1em"
                  }} align="center" component="h1" color="white">
                    TRABAJO
              </Typography>
            </Grid>
            <Grid item xs={10} sm={6} xl={4}>
                <Typography style={{
                  marginTop: "-20px",
                  marginBottom: "60px",
                  fontFamily: "mmcregular",
                  lineHeight:"1.1em",
                  fontSize: "clamp(1.2rem, 5vw, 0.4rem)"     
                }} align="center" component="h1" color="white">
                  Esta es la cara del aguante, lleva al límite sus capacidades y redefine el significado de fuerza.
                </Typography>

              </Grid>

            <Swiper 
                navigation={isMobile() ? true : false}
                slidesPerView={1}
                spaceBetween={0}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                centeredSlides={false}
                effect={"coverflow"}
                modules={isMobile() ? [Navigation] : []}

                className="mySwiper"

            >
                <SwiperSlide
                    style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center'
                }}>

                    <Box 
                        component="img"
                        src={atri1}
                        alt="atri1"
                        sx={{ maxWidth: isMobile()?  '90%' :"90%" }}
                    />
                    <Typography
                        style={{
                            color: "white",
                            fontSize: "clamp(.9rem, 3vw, 1.2rem)",
                            fontStyle: "normal",
                            fontFamily: "mmcmedium",
                            marginBottom: "20px",
                            marginTop: '20px',
                            letterSpacing: "0.1em",
                            maxWidth: "80%",
                            lineHeight:"1.08em",
                            maxWidth: isMobile()?  '90%' :"80%"
                        }}
                    >
                        SISTEMA DE SUSPENSIÓN RENOVADO PARA VIAJAR SIEMPRE CÓMODO SIN IMPORTAR EL CAMINO.
                    </Typography>
                </SwiperSlide>
                <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center'
                }}>

                    <Box
                        component="img"
                        src={atri2}
                        alt="atri2"
                        sx={{ maxWidth: isMobile()?  '90%' :"90%" }}
                    />
                    <Typography
                        style={{
                            color: "white",
                            fontSize: "clamp(.9rem, 3vw, 1.2rem)",
                            fontStyle: "normal",
                            fontFamily: "mmcmedium",
                            marginBottom: "20px",
                            marginTop: '20px',
                            letterSpacing: "0.1em",
                            maxWidth: "80%",
                            lineHeight:"1.08em",
                            maxWidth: isMobile()?  '90%' :"90%"
                        }}
                    >
                        MOTOR DE 4 CILINDROS PARA VIVIR AVENTURAS CON LA MÁXIMA POTENCIA.
                    </Typography>

                </SwiperSlide>
                <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center'
                }}>

                    <Box
                        component="img"
                        src={atri3}
                        alt="atri3"
                        sx={{ maxWidth: isMobile()?  '90%' :"90%" }}
                    />

                    <Typography
                        style={{
                            color: "white",
                            fontSize: "clamp(.9rem, 3vw, 1.2rem)",
                            fontStyle: "normal",
                            fontFamily: "mmcmedium",
                            marginBottom: "20px",
                            marginTop: '20px',
                            letterSpacing: "0.1em",
                            maxWidth: "80%",
                            lineHeight:"1.08em",
                            maxWidth: isMobile()?  '90%' :"90%"
                        }}
                    >
                        CAPACIDAD DE CARGA DE MÁS DE 1 TONELADA PARA LLEVAR LA AVENTURA A TODOS LADOS.
                    </Typography>

                </SwiperSlide>
                {/* <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center'
                }}>

                    <Box
                        component="img"
                        src={inte4}
                        alt="inte4"
                        sx={{ maxWidth: '100%' }}
                    />

                    <Typography
                        style={{
                            color: "white",
                            fontSize: "clamp(.9rem, 3vw, 1.2rem)",
                            fontStyle: "normal",
                            fontFamily: "mmc",
                            marginBottom: "20px",
                            marginTop: '20px',
                            letterSpacing: "0.1em",
                            maxWidth: "80%",
                            lineHeight:"1.08em"
                        }}
                    >
                        DELICADEZA Y DESEMPEÑO
                    </Typography>

                </SwiperSlide>
                <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center'
                }}>

                    <Box
                        component="img"
                        src={inte5}
                        alt="inte5"
                        sx={{ maxWidth: '100%' }}
                    />

                    <Typography
                        style={{
                            color: "white",
                            fontSize: "clamp(.9rem, 3vw, 1.2rem)",
                            fontStyle: "normal",
                            fontFamily: "mmc",
                            marginBottom: "20px",
                            marginTop: '20px',
                            letterSpacing: "0.1em",
                            maxWidth: "80%",
                            lineHeight:"1.08em"
                        }}
                    >
                        SISTEMA DE AUDIO BOSE®
                    </Typography>
                </SwiperSlide>
                <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center'
                }}>

                    <Box
                        component="img"
                        src={inte6}
                        alt="inte6"
                        sx={{ maxWidth: '100%' }}
                    />

                    <Typography
                        style={{
                            color: "white",
                            fontSize: "clamp(.9rem, 3vw, 1.2rem)",
                            fontStyle: "normal",
                            fontFamily: "mmc",
                            marginBottom: "20px",
                            marginTop: '20px',
                            letterSpacing: "0.1em",
                            maxWidth: "80%",
                            lineHeight:"1.08em"
                        }}
                    >
                        MEMORIA DE POSICIONES
                        DE ASIENTO
                    </Typography>
                </SwiperSlide> */}
            </Swiper>
        </Grid>
    );
}
