import * as React from 'react';

import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import "./fuentes/MMC-Bold.otf"
import "./fuentes/MMC-Medium.otf"
import "./fuentes/MMC-Regular.otf"
import "./fuentes/MMCOFFICE-Medium.ttf"


import './App.css'





const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(

  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
  </ThemeProvider>,
);
