//import * as React from 'react';
import React, { useState, useEffect } from "react";
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import fondo from '../images/video_hero_banner_new.mp4'
import stylesf from './section.css'
import PHEVazul from '../images/l200_logo.png'
import { auto } from '@popperjs/core';
import Grid from "@mui/material/Grid";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Form from "./Form";

import BgHeader from '../images/bg-header-l200-mitsubishi.jpg';

import axios from "axios";

export default function ProductHero(props) {

	const { set, typeDialog, setTypeDialog, url } = props;
	
	
  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };



  


  


  
  
  

  return (
	
    <div className="video-container" id="inicio1">
	<a id="inicio"> </a>	
      <video controls src={fondo} poster={BgHeader} autoPlay loop muted playsInline className='myVideo' style={{ marginTop: +isMobile()?  "95px" : "95px"}}/>

      <div className="overlay" sx={{ alignItems: "center", textAlign:"center"}}>
        <Grid container spacing={2}
        justifyContent="center"
        alignItems="center"
        >
          <Grid
          direction="row"
          justifyContent="center"
          alignItems="center"
          md={6}
          lg={6}
          >
            <img className="logo_blue"
            src={PHEVazul}
            alt="increase priority"
            width="100%"
            height={auto}
            alignItems="center"
          />
          </Grid>
        </Grid>
        
        

        <div class="section-content has-diagonal" style={{ marginTop: '0', marginBottom: "0" }}>

            <Grid container spacing={2} style={{ marginTop: +isMobile()?  '20px' :"20px", marginBottom: +isMobile()?  '20px' :"60px", placeContent: "space-around" }}>

              <Grid item xs={10} md={8} lg={8} sx={{ alignItems: "center"}}>
                <Typography style={{
                    fontSize: "clamp(1.3em, 5vw, 3rem)",
                    marginTop: "20px",
                    color: "white",
                    textAlign: "center",
                    alignContent: "center",
                    marginBottom: "0",
                    fontFamily: "mmcregular",
                    letterSpacing: "4",
                    lineHeight:"1em"
                  }} align="center" component="h1" color="white">
                    Desafío aceptado
                </Typography>
              </Grid>
            </Grid>

          <Grid style={{ marginTop: "-40px" }} >
          <Form set={set} setTypeDialog={setTypeDialog} typeDialog={typeDialog} currentUrl={url}/>
          </Grid>
					

          </div>

      </div>
    </div>


  )
}
