import * as React from "react";

import ProductHeroLayout from './ProductHeroLayout';
import axios from "axios";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ProductCTA from '../../modules/views/ProductCTA';

import phev from '../images/l200_logo.png'


import tenkiu from '../images/tenkiu.png'



import Frente from '../images/ultima2.png'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useSearchParams
} from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import AppAppBar from '../../modules/views/AppAppBar';
import AppFooter from '../../modules/views/AppFooter';


// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

const item = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    px: 5,
};
export default function ProductHero() {
    const [success, setSucces] = React.useState(false)
    const [searchParams] = useSearchParams();
    const [open, setOpen] = React.useState(false);
    const [pagoStatus, setPagoStatus] = React.useState("")


    const code = searchParams.get("id");



    React.useEffect(() => {

        console.log("idpago", code)
        handleValidarPago();
    }, []);


    const handleValidarPago = () => {
        setOpen(true)
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/api/validaPago/${code}`,
            headers: {
                'Accept': 'application/json',
            }
        };


        axios(config)
            .then(function (response) {
                console.log("datavali", response.data.status[0].status);

                if (response.data.status[0].status == "charge_pending") {
                    console.log("entro")
                    setTimeout(function () {
                        handleValidarPago();
                    }, 2000);
                } else if (response.data.status[0].status == "failed") {
                    setOpen(false)
                    setSucces(false)
                } else {
                    setOpen(false)
                    setSucces(true)

                }

            })
            .catch(function (error) {
                console.log(error);

            });
    }



    return (
        <React.Fragment>
            <AppAppBar />
            <Grid container direction="row"
                justifyContent='center'
                alignItems="center"
                sx={{ marginTop: "95px",
                    background:
                        " linear-gradient(48deg, rgba(37,37,37,1) 0%, rgba(21,21,21,0.989233193277311) 8%, rgba(21,21,21,1) 52%, rgba(21,21,21,1) 66%, rgba(61,60,63,0.9752275910364145) 97%)"
                }}
            >
                <Grid item xs={12} sm={4} md={5} sx={{ marginLeft: "45px" }}  >
                    {success == true ? (
                        <><Typography style={{
                            fontFamily: "mmcoffice",
                            fontSize: "clamp(0.4rem, 4vw, 1.8rem)",
                        }} align="left" component="h1" color="white">
                            Ya casi tienes tu nueva Mitsubishi OUTLANDER PHEV 2023.


                        </Typography><Typography style={{
                            fontFamily: "mmcregular",
                            fontSize: "clamp(0.4rem, 4vw, 1.2rem)",
                        }} align="left" component="h1" color="white">

                                El pago ha sido exitoso, <br/>
                                pronto nos contactaremos contigo.

                            </Typography>
                            <img
                                src={phev}
                                alt="increase priority"
                                style={{
                                    marginBottom: "25px",
                                    maxWidth: '60%',
                                    marginLeft: "-10px"
                                }}

                            />
                        </>
                    ) : (<><Typography style={{
                        fontFamily: "mmcoffice",
                        fontSize: "clamp(0.4rem, 4vw, 1.8rem)",
                    }} align="left" component="h1" color="#C5C5C5">
                        Transacción rechazada intente nuevamente


                    </Typography>
                        <img
                            src={phev}
                            alt="increase priority"
                            style={{
                                marginBottom: "25px",
                                maxWidth: '60%',
                                marginLeft: "-10px"
                            }}

                        />
                    </>)}
                </Grid>
                <Grid item xs={12} sm={4} md={6}>

                    <img
                        src={tenkiu}
                        alt="increase priority"
                        style={{
                            marginBottom: "90px",
                            maxWidth: '100%',
                        }}

                    />
                </Grid>
            </Grid>
            <ProductCTA />

            <AppFooter />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment >
    );
}
